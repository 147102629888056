<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('contacts_attributes_tab_title')"></h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-form ref="attributeValues" @submit.prevent="">
          <v-data-table-virtual
            :headers="fields"
            :items="items"
            :fields="fields"
            :loading="loading"
            :items-per-page-options="$itemsPerPageOptions"
            density="compact"
          >
            <template v-slot:[`item.edit`]="{ item }">
              <div class="link" color="primary" @click="openDetails(item)">
                <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
              </div>
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <template v-if="item.dataType == 'NUMBER'">
                <v-text-field
                  :id="'itemValue' + item.value"
                  v-model.trim="item.value"
                  type="number"
                  @change="saveItem(item)"
                  :loading="item.loading"
                  :rules="[$rules.isNumericList]"
                  @keyup.enter="$goNext($event.target)"
                />
              </template>
              <template v-else>
                <v-text-field
                  :id="'itemValue' + item.value"
                  v-model.trim="item.value"
                  type="string"
                  @change="saveItem(item)"
                  :loading="item.loading"
                  @keyup.enter="$goNext($event.target)"
                />
              </template>
            </template>
          </v-data-table-virtual>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-btn color="primary" @click="createNewAttribute()"><span v-html="$t('contacts_attributes_button_new_attribute')" /></v-btn>
        <v-btn
          color="secondary shadow-none"
          :to="{
            name: 'contacts_searchMasterData',
            params: { lang: $route.params.lang }
          }"
          ><span v-html="$t('contacts_attributes_tab_back_button')"
        /></v-btn>
      </v-col>
    </v-row>
    <!-- Neues Attribute Dialog -->
    <v-dialog v-model="newAttributeDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('contacts_attributes_dialogue_new_attribute')" />
        </v-card-title>
        <v-card-text>
          <!-- Funktion -->
          <v-form ref="attributeInsertForm">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_function')"></p>
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_de"
                  v-model="newAttribute.nameDe"
                  :label="$t('contacts_attributes_dialogue_attribute_name_de')"
                  type="text"
                  :rules="[$rules.required]"
                />
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_fr"
                  v-model="newAttribute.nameFr"
                  :label="$t('contacts_attributes_dialogue_attribute_name_fr')"
                  type="text"
                  :rules="[$rules.required]"
                />
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_it"
                  v-model="newAttribute.nameIt"
                  :label="$t('contacts_attributes_dialogue_attribute_name_it')"
                  type="text"
                  :rules="[$rules.required]"
                />
              </v-col>
              <!-- Sort -->
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_pos')"></p>
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_pos"
                  v-model="newAttribute.pos"
                  :label="$t('contacts_attributes_dialogue_attribute_pos')"
                  type="number"
                  :rules="[$rules.required]"
                />
              </v-col>
              <!-- Typ -->
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_type')"></p>
                <v-select
                  v-model="newAttribute.dataType"
                  :label="$t('contacts_attributes_dialogue_attribute_data_type')"
                  :items="dataTypes"
                  item-title="text"
                  item-value="value"
                  :rules="[$rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveNewAttributeAndClose()" :loading="createLoading">
            <span v-html="$t('contacts_attributes_dialogue_save_and_close')" />
          </v-btn>
          <v-btn color="secondary" @click="cancelNewAttribute()">
            <span v-html="$t('contacts_attributes_dialogue_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  Attribute Dialog -->
    <v-dialog v-model="editAttributeDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('contacts_attributes_dialogue_edit_attribute')" />
        </v-card-title>
        <v-card-text>
          <!-- Funktion -->
          <v-form ref="attributeEditForm">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_function')"></p>
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_de"
                  v-model="attributeToEdit.nameDe"
                  :label="$t('contacts_attributes_dialogue_attribute_name_de')"
                  type="text"
                  :rules="[$rules.required]"
                />
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_fr"
                  v-model="attributeToEdit.nameFr"
                  :label="$t('contacts_attributes_dialogue_attribute_name_fr')"
                  type="text"
                  :rules="[$rules.required]"
                />
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_name_it"
                  v-model="attributeToEdit.nameIt"
                  :label="$t('contacts_attributes_dialogue_attribute_name_it')"
                  type="text"
                  :rules="[$rules.required]"
                />
              </v-col>
              <!-- Sort -->
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_pos')"></p>
                <v-text-field
                  id="contacts_attributes_dialogue_attribute_pos"
                  v-model="attributeToEdit.pos"
                  :label="$t('contacts_attributes_dialogue_attribute_pos')"
                  type="number"
                  :rules="[$rules.required]"
                />
              </v-col>
              <!-- Typ -->
              <v-col cols="4" sm="4" md="4" lg="4">
                <p v-html="$t('contacts_attributes_dialogue_table_header_type')"></p>
                <v-select
                  v-model="attributeToEdit.dataType"
                  :label="$t('contacts_attributes_dialogue_attribute_data_type')"
                  :items="dataTypes"
                  item-title="text"
                  item-value="value"
                  :rules="[$rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="editAttributeAndClose()" :loading="editLoading">
            <span v-html="$t('contacts_attributes_dialogue_save_and_close')" />
          </v-btn>
          <v-btn color="secondary" @click="openDeleteAttributeDialog()">
            <span v-html="$t('contacts_attributes_dialogue_delete')" />
          </v-btn>
          <v-btn color="secondary" @click="cancelEditingAttribute()">
            <span v-html="$t('contacts_attributes_dialogue_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete Attribute Dialog -->
    <v-dialog v-model="deleteAttributeDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="$t('contacts_attributes_dialogue_delete_attribute_title')" />
        </v-card-title>
        <v-card-text>
          <!-- Funktion -->
          <v-row>
            <span v-html="formatedDeleteMessage" />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteAttribute()" :loading="deleteLoading">
            <span v-html="$t('contacts_attributes_dialogue_delete_and_close')" />
          </v-btn>
          <v-btn color="secondary" @click="cancelDeletingAttribute()">
            <span v-html="$t('contacts_attributes_dialogue_cancel')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { authService } from '@/services/auth.service'
import { Contact, ContactAttribute, ContactAttributeValue } from './contact'
import { CustomFormatter } from '../translations/CustomFormatter'
import { DTSHeader } from '@/services/BackendService'
import { useSearchContactAttributesRelationsPickerStore } from '@/store/SearchContactAttributesRelationsPickerStore'

import { useSearchContactAttributesRelationsStore } from '@/store/SearchContactAttributesRelationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'contact_attributes',
  props: { contact: { type: Contact, required: true }, contactId: { type: Number, required: true } },
  components: {},
  data() {
    return {
      editAttributeDialog: false,
      newAttributeDialog: false,
      deleteAttributeDialog: false,
      apiURL: apiURL + '/contactAttributes/',
      items: [],
      defaultAttribute: {
        nameDe: '',
        nameFr: '',
        nameIt: '',
        pos: '',
        dataType: ''
      },
      newAttribute: { ...this.defaultAttribute },
      attributeToEdit: {},
      formatedDeleteMessage: 'not yet',
      loading: false,
      editLoading: false,
      createLoading: false,
      deleteLoading: false,
      dataTypes: [
        {
          text: this.$t('contacts_attributes_dialogue_attribute_data_type_text'),
          value: 'TEXT'
        },
        {
          text: this.$t('contacts_attributes_dialogue_attribute_data_type_number'),
          value: 'NUMBER'
        }
      ],
      fields: <DTSHeader[]>[
        // edit
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // ID
        {
          title: this.$t('contact_attributes_table_header_contact_attribute_id'),
          key: 'contactAttributeId',
          sortable: false,
          align: 'left'
        },
        // Attribut
        {
          title: this.$t('contact_attributes_table_header_attribute_name'),
          key: this.$getLangKey(),
          sortable: true
        },
        // Sort
        {
          title: this.$t('contact_attributes_table_header_pos'),
          key: 'pos',
          sortable: true,
          align: 'left'
        },
        // Wert
        {
          title: this.$t('contact_attributes_table_header_value'),
          key: 'value',
          sortable: false,
          align: 'left'
        }
      ]
    }
  },
  computed: {},
  methods: {
    async saveItem(item) {
      item.loading = true
      try {
        let cav = new ContactAttributeValue()
        cav.contact = new Contact(this.contactId)
        cav.contactAttribute = new ContactAttribute(item.contactAttributeId)
        cav.value = item.value
        if (item.id) {
          await this.axios.patch(`${apiURL}/contactAttributeValues/${item.id}`, cav)
          emitter.emit('toastSuccess', {
            message: this.$t('toast_saved_message')
          })
        } else {
          let response = await this.axios.post(`${apiURL}/contactAttributeValues/`, cav)
          item.id = response.data.id
          emitter.emit('toastSuccess', {
            message: this.$t('toast_saved_message')
          })
        }
      } catch (e) {
        showError(e)
        //reload
        this.loadContactAttribute()
      } finally {
        item.loading = false
      }
    },
    async loadContactAttribute() {
      this.loading = true
      try {
        const response = await this.axios.get(this.apiURL + 'findAllAttributesAndValues', {
          params: {
            contactId: this.contactId
          },
          headers: { 'Content-Type': 'application/json' }
        })
        this.items = response.data
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    openDetails(item) {
      this.editAttributeDialog = true
      this.attributeToEdit = { ...item }
      const formatter = new CustomFormatter()
      this.formatedDeleteMessage = formatter.interpolate(this.$t('contacts_attributes_dialogue_delete_attribute_message'), [
        this.attributeToEdit[this.$getLangKey()],
        this.attributeToEdit.usages
      ])
    },
    createNewAttribute() {
      this.newAttributeDialog = true
    },
    openDeleteAttributeDialog() {
      this.deleteAttributeDialog = true
    },
    async deleteAttribute() {
      this.deleteLoading = true
      try {
        const result = await this.axios.delete(this.apiURL + this.attributeToEdit.contactAttributeId, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('contacts_attributes_dialogue_delete_success_toast')
          })
          this.loadContactAttribute()
          this.updateSearchCards()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteLoading = false
        this.editAttributeDialog = false
        this.deleteAttributeDialog = false
      }
    },
    closeDetails() {
      this.editAttributeDialog = false
    },
    cancelNewAttribute() {
      this.newAttributeDialog = false
      this.$refs.attributeInsertForm.reset()
      this.newAttribute = { ...this.defaultAttribute }
    },
    cancelEditingAttribute() {
      this.editAttributeDialog = false
      this.newAttribute = { ...this.defaultAttribute }
      this.$refs.attributeEditForm.reset()
    },
    cancelDeletingAttribute() {
      this.deleteAttributeDialog = false
    },
    async editAttributeAndClose() {
      if (!(await this.$refs.attributeEditForm.validate()).valid) {
        return false
      }
      this.editLoading = true
      try {
        const result = await this.axios.patch(
          this.apiURL + this.attributeToEdit.contactAttributeId,
          {
            nameDe: this.attributeToEdit.nameDe,
            nameFr: this.attributeToEdit.nameFr,
            nameIt: this.attributeToEdit.nameIt,

            pos: this.attributeToEdit.pos,
            dataType: this.attributeToEdit.dataType
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('contacts_attributes_dialogue_edit_success_toast')
          })
          this.loadContactAttribute(this.$route.params.id)
          this.updateSearchCards()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.editLoading = false
        this.editAttributeDialog = false
      }
    },
    async saveNewAttributeAndClose() {
      if (!(await this.$refs.attributeInsertForm.validate()).valid) {
        return false
      }
      this.createLoading = true
      try {
        const result = await this.axios.post(
          this.apiURL,
          { ...this.newAttribute, ...{ domainId: this.contact.domainId } },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('contacts_attributes_dialogue_create_success_toast')
          })
          this.loadContactAttribute()
          this.updateSearchCards()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.createLoading = false
        this.newAttributeDialog = false
      }
    },
    updateSearchCards() {
      useSearchContactAttributesRelationsStore().fetchContactAttributes(true)
      useSearchContactAttributesRelationsPickerStore().fetchContactAttributes(true)
    }
  },
  watch: {
    query: {
      handler() {
        this.$router.push({ name: 'attributes', query: this.query }).catch((e) => {
          /* duplicated route */
        })
      },
      deep: true
    }
  },
  mounted() {
    console.log('contact:', this.contact, this.contact.domainId)
    this.loadContactAttribute(this.$route.params.id, this.contact.domainId)
  }
})
</script>
