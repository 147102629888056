<template>
  <div class="container" id="contacts_edit" v-if="ready">
    <h1>{{ this.contact.name1 }}</h1>
    <v-tabs v-model="activeTab" color="primary">
      <v-tab id="overview"><span v-html="$t('contacts_overview_tab')" /></v-tab>
      <v-tab id="attributes"><span v-html="$t('contacts_attributes_tab')" /></v-tab>
      <v-tab id="relations"><span v-html="$t('contacts_relations_tab')" /></v-tab>
      <v-tab id="encashmentAndInvoices" v-if="$privileges.has({ path: '/smpEncashments', permission: 'read' })"
        ><span v-html="$t('contacts_encashmentAndInvoices_tab')"
      /></v-tab>
    </v-tabs>
    <v-window v-model="activeTab">
      <v-window-item value="overview">
        <contacts-overview :contact="this.contact" :contactId="contactId"></contacts-overview>
      </v-window-item>
      <v-window-item value="attributes">
        <attributes :contact="this.contact" :contactId="contactId"></attributes>
      </v-window-item>
      <v-window-item value="relations">
        <relations :contact="this.contact" :contactId="contactId"></relations>
      </v-window-item>
      <v-window-item value="encashmentAndInvoices">
        <encashmentAndInvoices :contact="this.contact" :contactId="contactId"></encashmentAndInvoices>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import _ from 'lodash'
import attributes from '@/views/contacts/attributes.vue'
import relations from '@/views/contacts/relations.vue'
import { privileges } from '@/services/privileges'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'
import { Contact } from './contact'
import ContactsOverview from '@/views/contacts/overview.vue'

import { defineComponent } from 'vue'
import dairiesRelations from '@/views/dairies/relations.vue'
import DairiesAddresses from '@/views/dairies/addresses.vue'
import dairiesOverview from '@/views/dairies/overview.vue'
import encashmentAndInvoices from '@/views/contacts/encashmentAndInvoices.vue'
import SmpEncashmentOrganisations from '@/views/contacts/cards/smpEncashmentOrganisations.vue'

export default defineComponent({
  name: 'contacts_edit',
  components: {
    SmpEncashmentOrganisations,
    dairiesOverview,
    DairiesAddresses,
    dairiesRelations,
    ContactsOverview,
    attributes,
    relations,
    encashmentAndInvoices
  },
  props: { contactId: { type: Number, required: true }, tab: { type: String } },
  data() {
    return {
      formPart: {
        id: null
      },
      ready: false,
      contact: new Contact(),
      tabs: [
        { id: 0, name: 'overview' },
        { id: 1, name: 'attributes' },
        { id: 2, name: 'relations' },
        { id: 3, name: 'encashmentAndInvoices' }
      ]
    }
  },
  computed: {
    readonly() {
      return !privileges.has({ path: '/contacts', permission: 'write' })
    },
    activeTab: {
      get() {
        return _.find(this.tabs, { name: this.tab }).id
      },
      set(tabindex) {
        this.$router
          .push({
            name: 'contacts_edit',
            params: {
              id: this.contactId,
              tab: _.find(this.tabs, { id: tabindex }).name
            }
          })
          .catch(() => {
            /* duplicated route */
          })
      }
    }
  },
  methods: {
    async loadContact() {
      try {
        const response = await this.axios.get(`${apiURL}/contacts/${this.contactId}`, { headers: { 'Content-Type': 'application/json' } })
        if (response) {
          this.contact = new Contact(response.data.id)
          this.ready = true
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    this.loadContact(this.id)
  }
})
</script>
