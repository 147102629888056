<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <h2 v-html="$t('contacts_relations_tab_title')"></h2>
      </v-col>
    </v-row>
    <v-col cols="12" sm="12" md="12" lg="12">
      <v-data-table :headers="fields" :items="items" :fields="fields" :loading="loading" :items-per-page-options="$itemsPerPageOptions" dense>
        <template v-slot:[`item.delete`]="{ item }">
          <div density="compact" icon>
            <v-icon @click="deleteItem(item)"> mdi-delete</v-icon>
          </div>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          <v-text-field :id="'itemValue' + item.value" />
          item.value
        </template>
        <template v-slot:[`item.validFrom`]="{ item }">
          <div>{{ $formatChDate(item.validFrom) }}</div>
        </template>
        <template v-slot:[`item.validUntil`]="{ item }">
          <div>{{ $formatChDate(item.validUntil) }}</div>
        </template>
        <template v-slot:[`item.debtor`]="{ item }">
          <v-checkbox v-model="item.debtor" @click="patchDebtor(item)" :id="'itemDebtor' + item.value" />
        </template>
      </v-data-table>
    </v-col>
    <v-card-actions>
      <v-btn color="primary" @click="dialogContact = true"><span v-html="$t('contact_relations_add')" /></v-btn>
      <v-btn color="secondary" :to="{ name: 'contacts_searchMasterData', params: { lang: $route.params.lang } }"
        ><span v-html="$t('contacts_relations_tab_back_button')"
      /></v-btn>
    </v-card-actions>

    <v-dialog v-model="dialogContact" max-width="800px">
      <v-card variant="flat">
        <v-card-title>
          <span v-html="this.$t(`contact_relation_dialog_header`)" />
        </v-card-title>
        <v-card-text>
          <v-form ref="newRelationDialog" @submit.prevent="">
            <v-col cols="12" sm="12" md="6">
              <div v-if="$getDebug()">
                tfdv{{ model.parentId }}<br />
                sel{{ model }}
              </div>
              <h2 v-html="this.$t(`contact_relation_dialog_header_parent`)" />
              <contactParentIdPickerVue
                :rules="[$rules.required]"
                :label="$t('contactparentorganisationid')"
                name="contactParentIdPickerVue"
                v-model="model.parentId"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <DbmDatePicker :rules="[$rules.required]" v-model="model.validFrom" :dateLabel="$t('contact_relation_dialog_valid_from')" :required="true" />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <DbmDatePicker v-model="model.validUntil" :dateLabel="$t('contact_relation_dialog_valid_until')" :required="false" />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    :loading="loadingContactRelationTypeData"
                    v-model="contactRelationTypeModel"
                    :rules="[$rules.isSelected]"
                    :label="$t('contact_relation_types')"
                    :items="contactRelationTypes"
                    :item-title="this.$route.params.langKey"
                    item-value="name"
                    clearable
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-btn color="secondary" @click="dialogRelationType = true"
                    ><span v-html="$t('contact_relation_dialog_add_relation_edit_relation_types')"
                  /></v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" sm="12" md="6">
                <v-checkbox v-model="model.debtor" label="contact_relation_dialog_header_debtor"></v-checkbox>
              </v-col>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveDialogContactRelation()"><span v-html="$t('contact_relation_dialog_add_relation')" /></v-btn>
          <v-btn color="secondary" @click="closeDialogContactRelation"><span v-html="$t('contact_relation_types_dialog_create_close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Dialog Contact Relation Types-->
    <v-dialog v-model="dialogRelationType" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="this.$t(`contact_relation_types_dialog_header`)" />
        </v-card-title>
        <v-card-text>
          <h2 v-html="this.$t(`contact_relation_types_dialog_header_parent`)" />
          <v-col cols="12" sm="12" md="12" lg="12">
            <div v-for="contactRelationType in contactRelationTypes">
              <v-btn density="compact" icon @click="openDialogRelationTypeDelete(contactRelationType)">
                <v-icon dense>mdi-delete</v-icon>
              </v-btn>
              {{ contactRelationType[$route.params.langKey] }}
            </div>
          </v-col>
        </v-card-text>
        <!-- Add Relation Type -->
        <v-card-actions>
          <v-btn color="primary" @click="dialogRelationTypeCreate = true" :loading="loadingContactRelationTypeData"
            ><span v-html="$t('contact_relation_types_dialog_add_relation')"
          /></v-btn>
          <v-btn color="secondary" @click="dialogRelationType = false"><span v-html="$t('contact_relation_types_dialog_save_close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Alert Delete RelationType-->
    <v-dialog v-model="dialogRelationTypeDelete" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="this.$t(`contact_relation_types_dialog_delete_header`)" />
        </v-card-title>
        <v-card-text>
          <v-checkbox v-model="deleteCheck" name="deleteCheck" :label="createSubTitleRelationTypeDelete"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteItemRelationType()" :disabled="!deleteCheck">
            <span v-html="this.$t(`contact_relation_types_dialog_delete_close`)" />
          </v-btn>
          <v-btn color="secondary" @click="dialogRelationTypeDelete = false"><span v-html="$t('contact_relation_types_dialog_delete_cancel')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create RelationType-->
    <v-dialog v-model="dialogRelationTypeCreate" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="this.$t(`contact_relation_types_dialog_create_header`)" />
        </v-card-title>
        <v-card-text>
          <v-form ref="newRelationTypeDialog" @submit.prevent="">
            <v-row>
              <v-col cols="6" sm="12" md="4">
                <v-text-field
                  id="nameDe"
                  :label="$t('contact_relation_types_dialog_create_nameDe')"
                  v-model="relationType.nameDe"
                  :rules="[$rules.required]"
                  @keyup.enter="$goNext($event.target)"
                />
              </v-col>
              <v-col cols="6" sm="12" md="4">
                <v-text-field
                  id="nameFr"
                  :label="$t('contact_relation_types_dialog_create_nameFr')"
                  v-model="relationType.nameFr"
                  :rules="[$rules.required]"
                  @keyup.enter="$goNext($event.target)"
                />
              </v-col>
              <v-col cols="6" sm="12" md="4">
                <v-text-field
                  id="nameIt"
                  :label="$t('contact_relation_types_dialog_create_nameIt')"
                  v-model="relationType.nameIt"
                  :rules="[$rules.required]"
                  @keyup.enter="$goNext($event.target)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveItemRelationType()" :loading="loadingContactRelationType">
            <span v-html="this.$t(`contact_relation_types_dialog_create_save`)" />
          </v-btn>
          <v-btn color="secondary" @click="dialogRelationTypeCreate = false"><span v-html="$t('contact_relation_types_dialog_create_close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { Contact } from './contact'
import contactParentIdPickerVue from '@/components/pickers/contactParentIdPicker.vue'
import { mapGetters } from 'vuex'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { authService } from '@/services/auth.service'
import { useSearchContactAttributesRelationsPickerStore } from '@/store/SearchContactAttributesRelationsPickerStore'

import { defineComponent } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'contactRelations',
  components: {
    DbmDatePicker,
    contactParentIdPickerVue
  },
  props: { contact: { Contact }, contactId: { type: Number, required: true } },
  data() {
    return {
      loading: false,
      loadingContactRelation: false,
      loadingContactRelationDate: false,
      loadingContactRelationType: false,
      loadingContactRelationTypeData: false,
      items: [],
      editItem: {},
      contactRelationTypes: [],
      dialogContact: false,
      dialogRelationType: false,
      dialogRelationTypeDelete: false,
      dialogRelationTypeCreate: false,
      deleteCheck: false,
      relationType: {
        nameDe: undefined,
        nameFr: undefined,
        nameIt: undefined
      },
      model: {
        id: undefined,
        validFrom: undefined,
        validUntil: undefined,
        parentId: undefined,
        relationTypeId: undefined,
        debtor: undefined
      },
      relationTypeIdDelete: '',
      relationTypeDelete: '',
      contactRelationTypeModel: String,
      fields: [
        {
          value: 'delete',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('contact_relation_parent_Name'),
          value: 'parentName',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('contact_relation_Type_Name'),
          value: `relationType${this.$route.params.upLangKey}`,
          sortable: true
        },
        {
          title: this.$t('contact_relation_valid_from'),
          value: 'validFrom',
          sortable: true
        },
        {
          title: this.$t('contact_relation_valid_until'),
          value: 'validUntil',
          sortable: true
        },
        {
          title: this.$t('contact_relation_debtor'),
          value: 'debtor',
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('ContactStore', ['contacts']),
    SearchContactAttributesRelationsPickerStore() {
      return useSearchContactAttributesRelationsPickerStore()
    },
    relationTypeUsage() {
      return this.items.filter((x) => x.relationTypeId === this.relationTypeIdDelete).length
    },
    createSubTitleRelationTypeDelete() {
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t(`contact_relation_types_dialog_delete_message`), [this.relationTypeDelete, this.relationTypeUsage])
    }
  },
  methods: {
    async deleteItem(item) {
      try {
        await this.axios.delete(`${apiURL}/contactRelations/${item.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        await this.loadContactRelations()
      } catch (e) {
        showError(e)
      }
    },
    async deleteItemRelationType() {
      try {
        await this.axios.delete(`${apiURL}/contactRelationTypes/${this.relationTypeIdDelete}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        this.dialogRelationTypeDelete = false
        this.loadContactRelations()
        this.loadRelationTypes()
      } catch (e) {
        showError(e)
      }
    },
    openDialogRelationTypeDelete(item) {
      this.relationTypeIdDelete = item.id
      this.relationTypeDelete = item[this.$route.params.langKey]
      this.dialogRelationTypeDelete = true
    },
    async saveDialogContactRelation() {
      if (!(await this.$refs.newRelationDialog.validate()).valid) {
        return
      }
      this.loadingContactRelation = true
      this.model.relationTypeId = this.contactRelationTypes.find((x) => x[this.$route.params.langKey] === this.contactRelationTypeModel).id
      this.postDialogContactRelation()
    },
    patchDebtor(item) {
      item.debtor = !item.debtor
      this.model = item
      this.patchDialogContactRelation(item)
    },
    async patchDialogContactRelation() {
      try {
        const result = await this.axios.patch(
          `${apiURL}/contactRelations/${this.model.id}`,
          {
            ..._.omit(this.model, [
              'childId',
              'parentId',
              'id',
              'parentName',
              'relationTypeNameDe',
              'relationTypeNameFr',
              'relationTypeNameIt',
              'relationTypeId'
            ])
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', {
            message: this.$t('toast_save_success_message')
          })
          this.loadContactRelations()
          this.loadRelationTypes()
          this.dialogContact = false
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactRelation = false
        this.model = {}
      }
    },
    async postDialogContactRelation() {
      this.loadingContactRelation = false
      try {
        this.model.childId = this.$route.params.id
        const result = await this.axios.post(`${apiURL}/contactRelations/`, { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.dialogContact = false
          emitter.emit('toastSuccess', {
            message: this.$t('toast_save_success_message')
          })
          await this.loadContactRelations()
          this.closeDialogContactRelation()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactRelation = false
      }
    },
    async saveItemRelationType() {
      if (!(await this.$refs.newRelationTypeDialog.validate()).valid) {
        return
      }
      this.loadingContactRelationType = true
      try {
        const result = await this.axios.post(`${apiURL}/contactRelationTypes/`, { ...this.relationType }, { headers: { 'Content-Type': 'application/json' } })
        this.loadContactRelations()
        this.loadRelationTypes()
        this.dialogRelationTypeCreate = false
        emitter.emit('toastSuccess', {
          message: this.$t('toast_save_success_message')
        })
        //reset relationType
        this.relationType = {
          nameDe: undefined,
          nameFr: undefined,
          nameIt: undefined
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactRelationType = false
      }
    },
    async loadContactRelations() {
      this.loadingContactRelationData = true
      try {
        const response = await this.axios.get(`${apiURL}/contactRelations/findParentContacts`, {
          params: {
            contactId: this.contactId
          },
          headers: { 'Content-Type': 'application/json' }
        })
        this.items = response.data
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactRelationData = false
      }
    },
    async loadRelationTypes() {
      this.loadingContactRelationTypeData = true
      try {
        const response = await this.axios.get(apiURL + '/contactRelationTypes/find')
        this.contactRelationTypes = await response.data
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactRelationTypeData = false
      }
    },
    closeDialogContactRelation() {
      this.model = {}
      this.contactRelationTypeModel = ''
      this.dialogContact = false
    }
  },
  mounted() {
    this.loadContactRelations(this.$route.params.id, this.contact.domainId)
    this.loadRelationTypes()
  }
})
</script>
