<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-list>
          <v-list-item>
            <v-list-item-subtitle v-html="$t('contacts_overview_tab_header_general')"></v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-col>
              <v-text-field
                v-model.trim="contact.contactNo"
                @blur="patchContact"
                type="text"
                id="contacts_overview_tab_general_uid"
                :label="$t('contacts_overview_tab_general_uid')"
                @keyup.enter="patchContact"
              />
            </v-col>
            <v-col>
              <v-text-field
                :disabled="true"
                v-model="contact.id"
                type="text"
                id="contacts_overview_tab_general_id"
                :label="$t('contacts_overview_tab_general_id')"
              />
            </v-col>
          </v-list-item>
        </v-list>
        <v-divider class="address-divider"></v-divider>
        <v-list>
          <v-list-item v-if="this.addressesMasterData.length > 0">
            <v-list-item-subtitle v-html="$t('contacts_overview_tab_address_master_data_selected')"></v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-for="address in this.addressesMasterData">
            <div>
              <v-container fluid>
                <v-row>
                  <!--                  <v-col cols="12" md="12" sm="12">-->
                  <v-checkbox
                    v-model="address.default"
                    :key="address.uid"
                    :label="$t('contacts_overview_default_address')"
                    :disabled="address.default === true"
                    @click="changeDefaultAddress(address.addressId)"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="address.defaultEncashment"
                    :key="address.uid"
                    :label="$t('contacts_overview_default_encashment_address')"
                    :disabled="address.defaultEncashment === true"
                    hide-details
                    @click="changeDefaultEncashmentAddress(address.addressId)"
                  ></v-checkbox>
                  <span>{{ address.name1 }} {{ address.name2 }} {{ address.locality }} </span>
                  <!--                  </v-col>-->
                </v-row>
              </v-container>
            </div>
          </v-list-item>
          <v-list-item v-if="this.addressesIndependent.length > 0">
            <v-list-item-subtitle v-html="$t('contacts_overview_tab_address_independent_selected')"></v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-for="address in this.addressesIndependent">
            <v-checkbox
              v-model="address.default"
              :key="address.uid"
              :label="$t('contacts_overview_default_address')"
              :disabled="address.default === true"
              @click="changeDefaultAddress(address.addressId)"
            ></v-checkbox>
            <v-checkbox
              v-model="address.defaultEncashment"
              :key="address.uid"
              :label="$t('contacts_overview_default_encashment_address')"
              :disabled="address.defaultEncashment === true"
              @click="changeDefaultEncashmentAddress(address.addressId)"
            ></v-checkbox>
            <span>{{ address.name1 }} {{ address.name2 }} {{ address.locality }} </span>
            <v-icon dense class="trailing" @click="editAddress(address.addressId)">mdi-pencil</v-icon>
            <v-icon dense class="trailing" @click="openDeleteDialog(address.addressId)">mdi-delete</v-icon>
          </v-list-item>
          <v-list-item>
            <v-list-item-subtitle v-html="$t('contacts_overview_default_contact_title')"></v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-list-item>
          <address-preview :address="defaultAddress" :hide-title="true"></address-preview>
          <v-btn
            id="btn_add_independent_contact"
            style="float: right"
            class="ms-3"
            color="secondary"
            @click="createAddress"
            v-html="$t('contacts_overview_tab_address_btn_create')"
          ></v-btn>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-list>
          <v-list-item>
            <v-list-item-subtitle v-html="$t('contacts_overview_tab_header_reason')"></v-list-item-subtitle>
          </v-list-item>
          <v-data-table :headers="fields" :items="reasons" :fields="fields" :loading="loadingReasons" hide-default-footer>
            <template v-slot:[`item.validUntil`]="{ item }">
              <template v-if="item.validUntil">
                {{ $formatChDate(item.validUntil) }}
              </template>
            </template>
            <template v-slot:[`item.validFrom`]="{ item }">
              <template v-if="item.validFrom">
                {{ $formatChDate(item.validFrom) }}
              </template>
            </template>
            <template v-slot:[`item.validUntilViewable`]="{ item }">
              <template v-if="item.validUntilViewable">
                {{ $formatChDate(item.validUntilViewable) }}
              </template>
            </template>
            <template v-slot:[`item.deletion`]="{ item }">
              <template v-if="item.deletion">
                {{ $formatChDateTime(item.deletion) }}
              </template>
            </template>
          </v-data-table>
          <v-list-item>
            <v-btn
              id="btn_add_independent_contact"
              style="float: right"
              class="ms-3"
              color="secondary"
              @click="deactivateContact"
              :disabled="disableContactDisableBtn"
              v-html="$t('contacts_overview_tab_reason_btn_deactivate')"
            ></v-btn>
          </v-list-item>
        </v-list>
        <v-divider class="address-divider"></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-subtitle v-html="$t('contacts_overview_tab_title2')"></v-list-item-subtitle>
          </v-list-item>
          <!--          <rdhComponent v-if="addresses.length > 0" :show="true" :remarks_show="true" :remarks_tag="'contacts_' + contactId" :documents_show="true" :documents_tag="'contacts_' + contactId" :history_show="true" :history_url="'/contacts/' + this.contactId" ref="rdh"></rdhComponent>-->
          <rdhComponent
            :show="true"
            :remarks_show="true"
            :remarks_tag="'contacts_' + this.contactId"
            :documents_show="true"
            :documents_tag="'contacts_' + this.contactId"
            :history_show="true"
            :history_url="'/contacts/' + this.contactId"
            ref="rdh"
          ></rdhComponent>
        </v-list>
      </v-col>
    </v-row>

    <!--    Dialog Edit Contact-->
    <addressDialog :default-address-id="addressEditId" :contact-id="contactId" ref="addressDialog" @reload="load"></addressDialog>

    <!-- Alert Delete Contact-->
    <v-dialog v-model="dialogDeleteContact" max-width="800px">
      <v-card>
        <v-card-title>
          <span v-html="this.$t(`contacts_overview_tab_address_dialog_delete_header`)" />
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="dialogDeleteCheck"
            name="deleteCheck"
            :label="$t('contacts_overview_tab_address_dialog_delete_message')"
            :disabled="defaultAddress === undefined || defaultAddress.addressId === addressEditId"
          ></v-checkbox>
          <v-alert border="top" colored-border type="info" elevation="2" v-show="defaultAddress === undefined || defaultAddress.addressId === addressEditId">
            <span v-html="$t('contacts_overview_tab_address_dialog_delete_standard_message')"></span>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteAddress()" :disabled="!dialogDeleteCheck">
            <span v-html="this.$t(`contacts_overview_tab_address_dialog_delete_close`)" />
          </v-btn>
          <v-btn color="secondary" @click="dialogDeleteContact = false" v-html="$t('contacts_overview_tab_address_dialog_delete_cancel')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { app, apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { BaseAddress, Contact } from './contact'
import addressPreview from '@/views/masterdata/addressPreview.vue'
import addressDialog from '@/views/contacts/addressDialog.vue'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import _ from 'lodash'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'contactsOverview',
  components: {
    rdhComponent,
    addressDialog,
    addressPreview
  },
  props: { contact: { Contact }, contactId: { type: Number, required: true }, test: {} },
  data() {
    return {
      addresses: [],
      reasons: [],
      addressEditId: 2,
      dialogEditContact: false,
      dialogDeleteCheck: false,
      dialogDeleteContact: false,
      loadingReasons: false,
      loadingContactPatch: false,
      disableDeletion: false,
      fields: [
        {
          // reason
          title: this.$t('contacts_overview_tab_reasons_table_column_reason'),
          value: 'code',
          sortable: false,
          align: 'left',
          width: '45%'
        },
        {
          // from
          title: this.$t('contacts_overview_tab_reasons_table_column_from'),
          value: 'validFrom',
          sortable: false,
          align: 'left',
          width: '15%'
        },
        {
          // until
          title: this.$t('contacts_overview_tab_reasons_table_column_until'),
          value: 'validUntil',
          sortable: false,
          align: 'left',
          width: '15%'
        },
        {
          // until
          title: this.$t('contacts_overview_tab_reasons_table_column_deletion'),
          value: 'deletion',
          sortable: false,
          align: 'left',
          width: '25%'
        },
        {
          // appears until
          title: this.$t('contacts_overview_tab_reasons_table_column_appears_until'),
          value: 'validUntilViewable', //todo
          sortable: false,
          align: 'left',
          width: '15%'
        }
      ]
      // address: { addressId: Number, isDefault: true },
    }
  },
  computed: {
    disableContactDisableBtn() {
      return this.loadingContactPatch || this.hasValidContactReason || this.contact.deletion != null || this.disableDeletion
    },
    defaultAddress() {
      let test = this.addresses.find((x) => x.default === true)
      return new BaseAddress(test)
    },
    addressesIndependent() {
      return this.addresses.filter((a) => a.addressType === 'CONTACT')
    },
    addressesMasterData() {
      return this.addresses.filter((a) => a.addressType !== 'CONTACT')
    },
    hasValidContactReason() {
      return (
        this.reasons.filter((r) => !r.validUntil || new Date(r.validUntil).toISOString().split('T')[0] >= new Date().toISOString().split('T')[0]).length > 0
      )
    }
  },
  methods: {
    deactivateContact() {
      this.contact.deletion = new Date().toISOString().split('T')[0]
      this.patchContact()
    },
    async patchContact() {
      try {
        const result = await app.axios.patch(`${apiURL}/contacts/${this.contact.id}`, this.contact)
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingContactPatch = false
        if (this.contact.deletion != null) {
          this.disableDeletion = true
        }
        this.load()
      }
    },
    async load() {
      try {
        const response = await this.axios.get(`${apiURL}/contacts/${this.contactId}/addressesUnified`, { headers: { Accept: 'application/json' } })
        this.addresses = response.data
      } catch (e) {
        this.addresses = showError(e)
      } finally {
        this.loadReasons()
      }
    },
    formatDateAddOneYear(dateValue) {
      if (!dateValue) {
        return null
      }
      if (dateValue.length > 7) {
        const [year, month, dayTime] = dateValue.split('-')
        const [day, time] = dayTime.split('T')
        return `${parseInt(year) + 1}-${month}-${day}`
      } else {
        return null
      }
    },
    async loadReasons() {
      this.loadingReasons = false
      try {
        const response = await this.axios.get(`${apiURL}/contacts/${this.contactId}/contactReasons`, { headers: { Accept: 'application/json' } })
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].validUntilViewable = this.formatDateAddOneYear(response.data[i].validUntil)
        }
        this.reasons = response.data
      } catch (e) {
        this.reasons = []
        showError(e)
      } finally {
        this.loadingReasons = false
      }
    },
    async deleteAddress() {
      try {
        const result = await this.axios.delete(apiURL + '/contactAddresses/' + this.addressEditId, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.dialogDeleteContact = false
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.load()
        }
      } catch (e) {
        showError(e)
      }
    },
    async changeDefaultAddress(addressId) {
      try {
        const addressType = this.addresses.find((a) => a.addressId === addressId).addressType
        const result = await this.axios.post(
          `${apiURL}/contacts/${this.contactId}/setDefaultAddress`,
          {},
          {
            params: {
              addressType: addressType,
              addressId: addressId
            }
          }
        )
        if (result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.load()
      }
    },
    async changeDefaultEncashmentAddress(addressId) {
      try {
        const addressType = this.addresses.find((a) => a.addressId === addressId).addressType
        const result = await this.axios.post(
          `${apiURL}/contacts/${this.contactId}/setDefaultEncashmentAddress`,
          {},
          {
            params: {
              addressType: addressType,
              addressId: addressId
            }
          }
        )
        if (result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.load()
      }
    },
    editAddress(id) {
      this.$refs.addressDialog.openDialog(id)
    },
    reload() {
      this.load()
    },
    createAddress() {
      this.$refs.addressDialog.openDialogCreate()
    },
    openDeleteDialog(id) {
      this.addressEditId = id
      this.dialogDeleteCheck = false
      this.dialogDeleteContact = true
    }
  },
  watch: {
    query: {
      handler() {
        this.$router.push({ name: 'overview', query: this.query }).catch((e) => {
          /* duplicated route */
        })
      },
      deep: true
    }
  },
  mounted() {
    this.load()
  }
})
</script>

<style lang="scss" scoped>
.address-divider {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
}
</style>
