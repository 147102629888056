<template>
  <v-row>
    <v-col cols="9">
      <SmpEncashmentOrganisations v-if="$privileges.has({ path: '/smpEncashments', permission: 'read' })"></SmpEncashmentOrganisations>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue'
import SmpEncashmentOrganisations from '@/views/contacts/cards/smpEncashmentOrganisations.vue'

export default defineComponent({
  name: 'encashmentAndInvoices',
  components: { SmpEncashmentOrganisations }
})
</script>
