<template>
  <v-card variant="flat" class="mx-auto">
    <v-card-title><span v-html="$t('contacts_encashmentAndInvoices_encashment_organisations')" /> </v-card-title>
    <v-card-text>
      <v-data-table-virtual density="compact" :headers="fields" :items="groupedItems" :hide-default-footer="true" class="custom-simple-table">
        <template v-slot:item.id="{ item }">
          <div v-if="item.show">
            <div>
              {{ item.id }}
            </div>
          </div>
        </template>

        <template v-slot:item.dairyIdent="{ item }">
          <router-link v-if="item.show" :to="{ name: 'contacts_edit', params: { id: item.contactIdDairy, tab: 'overview' } }" class="tableaction link">
            {{ item.dairyIdent }}
          </router-link>
          <div v-if="item.show">
            <div v-if="!item.smpEncashmentEntityId" class="red-writing">
              {{ item.dairyName }}
            </div>
            <div v-else>
              {{ item.dairyName }}
            </div>
          </div>
        </template>

        <template v-slot:item.info="{ item }">
          <div v-if="item.show">
            {{ item.info }}
          </div>
        </template>
        <template v-slot:item.smpEncashmentEntityId="{ item }">
          <v-select
            v-model="item.smpEncashmentEntityId"
            v-if="$privileges.has({ path: '/smpEncashments', permission: 'write' })"
            id="contacts_encashmentAndInvoices_encashment_organisation"
            :label="$t('contract_edit_encashment_organisation')"
            :items="activeSmpEncashmentEntities"
            @update:model-value="
              (value) => {
                item.smpEncashmentEntityId = value
                patchSMPEncashment(item)
              }
            "
            :item-title="entityName"
            item-value="id"
          />
        </template>
        <template v-slot:item.validFrom="{ item }">
          <DbmMonthPicker v-model="item.validFrom" :dateLabel="$t('from')" :clearable="true" @change="patchSMPEncashment(item)" />
        </template>
        <template v-slot:item.validUntil="{ item }">
          <DbmMonthPicker v-model="item.validUntil" :dateLabel="$t('until')" :clearable="true" returnType="lastOfMonth" @change="patchSMPEncashment(item)" />
        </template>
        <template v-slot:item.add="{ item }">
          <v-btn
            :loading="item.addLoading"
            icon="mdi-plus"
            size="x-small"
            class="custom-button-small"
            @click="addSMPEncashment(item)"
            v-if="$privileges.has({ path: '/smpEncashments', permission: 'write' }) && item.show && item.validUntil"
          />
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn
            icon=" mdi-delete"
            size="x-small"
            variant="text"
            :loading="item.deleteLoading"
            @click="deleteSMPEncashment(item)"
            v-if="$privileges.has({ path: '/smpEncashments', permission: 'write' })"
          />
        </template>
      </v-data-table-virtual>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="dialog = true" type="submit"
        ><span v-html="$t('contacts_encashmentAndInvoices_encashment_history')" v-if="$privileges.has({ path: '/smpEncashments', permission: 'history' })"
      /></v-btn>
    </v-card-actions>
  </v-card>

  <!-- Verlauf -->
  <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="encashment_history_dialog">
    <v-card>
      <v-card-text>
        <rdhComponent
          :show="$privileges.has({ path: '/smpEncashments', permission: 'history' })"
          :history_show="true"
          :history_url="'/contacts/' + $route.params.id + '/smpEncashments'"
          ref="rdh"
        ></rdhComponent>
      </v-card-text>
      <v-card-actions>
        <v-btn id="close-btn" @click="closeDialog()" type="submit"><span v-html="$t('close')" /> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { defineComponent } from 'vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { DTSHeaders } from '@/services/BackendService'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import _ from 'lodash'
import { useSmpEncashmentEntityStore } from '@/store/enums/SmpEncashmentEntityStore'

interface SmpEnchashmentEntity {
  ident: number
  name: string
  displayName?: string
}

export default defineComponent({
  name: 'smpEncashmentOrganisations',
  components: { DbmMonthPicker, rdhComponent: rdhComponent, DbmDatePicker: DbmDatePicker },

  data() {
    return {
      smpEncashments: [],
      loading: true,
      deleteLoading: false,
      addLoading: false,
      dialog: false,
      fields: <DTSHeaders>[
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_id'),
          key: 'id',
          sortable: false,
          width: '1%'
        },
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_mbh'),
          key: 'dairyIdent',
          sortable: false
        },
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_dairy_type'),
          key: `dairyTypeName${this.$getUpLang()}`,
          sortable: false,
          width: '7%'
        },
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_organisation'),
          align: 'start',
          sortable: false,
          key: 'smpEncashmentEntityId',
          width: '20%'
        },
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_from'),
          key: 'validFrom',
          sortable: false,
          width: '15%'
        },
        {
          title: this.$t('contacts_encashmentAndInvoices_encashment_until'),
          key: 'validUntil',
          sortable: false,
          width: '15%'
        },
        { title: '', key: 'add', width: '1%' },
        { title: '', key: 'delete', width: '1%' }
      ],
      smpEncashmentEntityStore: useSmpEncashmentEntityStore()
    }
  },

  computed: {
    activeSmpEncashmentEntities(): SmpEnchashmentEntity[] {
      return this.smpEncashmentEntityStore.activeSmpEncashmentEntities.map((org: SmpEnchashmentEntity) => ({
        ...org,
        displayName: `${org.ident} ${org.name}`
      }))
    },
    groupedItems() {
      return this.smpEncashments.map((item, index, items) => ({
        ...item,
        show: this.show(item, index, items)
      }))
    }
  },
  methods: {
    entityName(item: SmpEnchashmentEntity) {
      return item.displayName || item.name
    },
    magic(item, val) {
      console.log('modelvalue,value', item, val)
    },

    closeDialog() {
      this.dialog = false
    },
    async loadSMPEncashments(contactId) {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/contacts/' + contactId + '/smpEncashments', { headers: { Accept: 'application/json' } })
        const items = await response.data
        return Array.isArray(items) ? items : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async patchSMPEncashment(item) {
      try {
        const result = this.axios.patch(`/api/smpEncashments/${item.id}`, {
          id: item.id,
          validFrom: item.validFrom ? item.validFrom : null,
          validUntil: item.validUntil ? item.validUntil : null,
          smpEncashmentEntityId: item.smpEncashmentEntityId ? item.smpEncashmentEntityId : null
        })
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        this.smpEncashments = []
        showError(e)
      } finally {
        this.smpEncashments = await this.loadSMPEncashments(this.$route.params.id)
      }
    },
    async deleteSMPEncashment(item) {
      this.deleteLoading = true
      try {
        const result = await this.axios.delete(apiURL + '/smpEncashments/' + item.id, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.smpEncashments = await this.loadSMPEncashments(this.$route.params.id)
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.deleteLoading = false
      }
    },

    async addSMPEncashment(item) {
      this.addLoading = true

      const validUntilDate = new Date(item.validUntil)
      validUntilDate.setDate(validUntilDate.getDate() + 1)
      item.validFrom = validUntilDate.toISOString().split('T')[0]

      item = _.omit(item, ['id', 'show', 'validUntil', 'smpEncashmentEntityId'])
      try {
        const result = await this.axios.post(
          apiURL + '/smpEncashments/',
          {
            ...item
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.smpEncashments = await this.loadSMPEncashments(this.$route.params.id)
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      } finally {
        this.addLoading = false
      }
    },
    show(item, index, items) {
      return index === 0 || (items && item.contactIdDairy !== items[index - 1].contactIdDairy)
    }
  },
  async mounted() {
    await this.smpEncashmentEntityStore.fetchActiveSmpEncashmentEntities()
    this.smpEncashments = await this.loadSMPEncashments(this.$route.params.id)
  }
})
</script>
<style scoped>
.custom-button-small {
  margin: 0 !important;
  padding: -2px !important;
}
</style>
